import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueCarousel from "vue-carousel";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import VueI18n from "vue-i18n";
import router from "./router/router";
import store from "./store";
import axios from "axios";
import { Plugin } from "vue-responsive-video-background-player";
import QrcodeVue from "qrcode.vue";

import en from "@/locales/en.json";
import fr from "@/locales/fr.json";

Vue.use(Vuelidate);
Vue.use(VueApexCharts);
Vue.use(Plugin);
Vue.use(QrcodeVue);
Vue.use(VueI18n);
Vue.use(VueCarousel);

Vue.config.productionTip = false;

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

const messages = {
  en,
  fr,
};

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
