<template>
  <div class="iframe-container">
    <iframe :src="viewerUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "PDFViewer",
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    viewerUrl() {
      return `/pdfjs-viewer/viewer.html?file=${encodeURIComponent(
        this.pdfUrl
      )}`;
    },
  },
};
</script>

<style scoped>
.iframe-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
